import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';
import LogoHorizontal from '../../components/logo/LogoHorizontal';

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();
  return (
    <LoginLayout>
      <Stack spacing={4} sx={{ mb: 6, mt: 11, position: 'relative' }}>
        <Typography variant="h3" color="primary">Bienvenido a Conecta </Typography>
        <Typography variant="p">Por favor, inicia sesión en Ingreso SOC.</Typography>
        {/* <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            Create an account
          </Link>
        </Stack> */}


      </Stack>

      {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
      </Alert> */}

      <AuthLoginForm />

    </LoginLayout>
  );
}
